<template>
    <v-footer color="secondary">
        <v-container fluid class="text-center pa-0">
            <v-row justify="center" align="center" class="my-1">
                <v-col cols="10" class="pa-0">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-0">
                    <v-btn 
                        large 
                        exact 
                        icon 
                        v-for="page in pages" 
                        :key="page.title" 
                        :to="{ name: page.link }" 
                        :class="`${page.class} ma-3 my-1`" 
                        :aria-label="`Link to ${page.title} page`"
                    >
                        <v-icon :class="page.class">{{ page.icon }}</v-icon>
                    </v-btn>
                    <v-btn 
                        large 
                        exact 
                        icon 
                        v-for="page in socialPages" 
                        :key="page.title" 
                        :href="page.link" 
                        target="_blank" 
                        rel="noreferrer" 
                        :class="`${page.class} ma-3 my-1`" 
                        :aria-label="`Visit our ${page.title} page`"
                    >
                        <v-icon :class="page.class">{{ page.icon }}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="my-2 py-0">
                    Feel Travelling &copy; {{ new Date().getFullYear() }}
                </v-col>
            </v-row>
            <v-row v-if="Object.keys(icons).includes(this.$route.name)">
                <v-col cols="12" class="overline pt-0 icon-attribution">
                    Icons made by <a href="https://www.flaticon.com/authors/freepik" :title="icons[this.$route.name]">{{ icons[this.$route.name] }}</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
import { mdiHome, mdiAirplane, mdiCamera, mdiInformation } from "@mdi/js";
import { mapGetters } from "vuex";

export default {
    name: "Footer",

    computed: {
        ...mapGetters(["socialPages"])
    },

    data() {
        return {
            pages: [
                {
                    class: "footer-icon",
                    title: "Home",
                    link: "home",
                    icon: mdiHome
                },
                {
                    class: "footer-icon",
                    title: "Travel",
                    link: "travel",
                    icon: mdiAirplane
                },
                {
                    class: "footer-icon",
                    title: "Photography",
                    link: "photography",
                    icon: mdiCamera
                },
                {
                    class: "footer-icon",
                    title: "About",
                    link: "about",
                    icon: mdiInformation
                }
            ],
            icons: {
                server_error: "Freepik",
                not_found: "Freepik",
                not_ready: "xnimrodx"
            }
        };
    },

    methods: { }
};
</script>

<style lang="scss" scoped>
.v-footer {
    background-image: linear-gradient(rgba($secondary_color, 0.74), rgba($secondary_color, 0.74)), url("/static/img/main.jpg");
    background-position: 50% 75%;
    background-size: cover;
    font-size: 12px;
}

.icon-attribution {
    opacity: 50%;
}
</style>
